var navPosition = $(".navbar-scroll").offset().top;

//當滑動到哪裡，增加ＣＳＳ標籤
$(window).on("scroll",function () {
  var navTop = $(window).scrollTop();
  if (navPosition < navTop) {
    $(".navbar-scroll").addClass("fixed-top");
    $(".gototop").fadeIn();
  } else {
    $(".navbar-scroll").removeClass("fixed-top");
    $(".gototop").fadeOut();
  }
});

$(".gototop").on("click",function () {
  $("html,body").animate({
      scrollTop: $("html").offset().top
  });
});
