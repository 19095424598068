$('.inside-menu').owlCarousel({
  // center: true,
  loop:false,
  nav:false,
  dots: false,
  autoWidth:true,
  responsive:{
      0:{
          items:3.5
      },
      600:{
          items:8
      },
      1000:{
          items:12
      }
    }
})
